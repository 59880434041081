<template>
    <!-- 表单填写，跟材料上传 -->
    <div class="must-container"> 
        <el-tabs type="border-card">
            <el-tab-pane label="填写表单信息">
                <div class="form-container">
                    <el-button class="hisform-btn" type="primary" @click="showFormDataDialog">历史表单数据</el-button>
                    <ParseFormViewDiv
                    ref="parseFormViewDiv"
                    :formData="formContent"
                    size="100%"
                    ></ParseFormViewDiv>
                </div>
            </el-tab-pane>
            <el-tab-pane label="上传材料">
                <TableRanking
                    :itemList="materialList"
                    :itemWorkList="workUpList"
                    :isBack="isBack"
                    @uploadFileSuccess="uploadFileSuccess"
                ></TableRanking>
            </el-tab-pane>
        </el-tabs>
        <!-- 历史表单show -->
        <el-dialog title="历史表单数据选择" :visible.sync="dgVisible" width="50%">
            <div class="dialog-wrap">
                <div class="mt-10">
                <el-table
                    :data="tableData" stripe border height="280" size="mini"
                    @row-click="fillFormSubData"
                    highlight-current-row>
                    <el-table-column
                    label="序号"
                    type="index"
                    width="60"
                    align="center"
                    style="padding: 0"
                    ></el-table-column>
                    <el-table-column label="事项名称" min-width="310">
                    <template slot-scope="scope">
                        <span class="max-one-line">{{ scope.row.sxmc || "" }}</span>
                    </template>
                    </el-table-column>

                    <el-table-column label="申请时间" width="210">
                    <template slot-scope="scope">
                        <span class="max-one-line">{{ scope.row.createtime.split("T")[0] }}</span>
                    </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                    class="mt-10"
                    background
                    hide-on-single-page
                    :total="total"
                    :page-size="pageSize"
                    @current-change="changePageNum"
                ></el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
import ParseFormViewDiv from "@/components/declareComponents/formMaking/ParseFormViewDiv";  // 表单
import TableRanking from "@/components/declareComponents/tableRanking.vue";                 // 材料

export default {
    components: { 
        ParseFormViewDiv,    // 表单回显
        TableRanking,        // 材料
    },
    props: { 
    },
    data() {
        return {
            formData: null,     // 原始信息
            formContent: {},    // 转化后信息
            dgVisible: false,   // 历史表单：弹窗
            tableData: [],      // 历史表单：数据
            pageNum: 1,         // 历史表单：当前分页
            pageSize: 5, 
            total: 0,           // 历史表单：分页总数
            allFormDataList: [], // 历史表单：获取的所有数据
            materialList: [], // 网络请求回来的材料列表
            workUpList: [], //  通过工作台传的上传
            // 与materialList结构一样，上传后增加files字段
            // 如果没上传过材料 提交申报时就用materialList的值
            materials: [],  // 当前上传的材料
            isBack:"",
            enterpriseInfo: {}, 	// 更新数据格式后的企业基本信息
        }
    },
    computed: {
		...mapGetters({
			loginUserInfo: "getUserInfo", 			// 账号登录后基本信息
			itemInfo: "getCurrentDeclareItem",		// 申报事项的基本信息
			companyBaseInfo: "getAccountBaseInfo",		// 申报账户（公司）基本信息
			projectItem: "getCurrentProjectItem", 	// 从我的项目进入时有值
		}),
    },
    created() {
        this.getFromData();                 // 获取表单信息
        // this.getHistoryFormDataList();      // 获取历史表单列表
    },
    mounted() {
        this.isBack = this.$route.query.isBack; // ???
        console.log("this.isBack", this.isBack)
        this.getHistoryFormDataList();      // 获取历史表单列表
        this.getMaterialList();            // 获取材料的数据
    },
    methods: {
        // 表单数据共享对话框
        showFormDataDialog() { 
            this.dgVisible = true;
        },
		/**
		 * 检查材料
		 */
		checkMaterial() {
            // 获取表单数据 
            // 此次有错
            let projectFormData = this.$refs.parseFormViewDiv.getFormData();  
            // 获取提交状态的数据是否符合标准 
            let submitData = this.handleSubmitData(false, projectFormData);  
            if( submitData.materials && submitData.materials.length > 0 ) {
                let materialList = submitData.materials;
                for (let i = 0; i < materialList.length; i++) {
                    let item = materialList[i]
                    if (item.IS_NEED === "1" || item.isneed === "1") {
                        if (!item.files || item.files.length <= 0) {
                            return false
                        }
                    }
                }
            }  
            return true
		},
		//检查必须的文件列表
		checkFiles(submitData) {
			// 必传材料验证
			if (submitData.materials && submitData.materials.length > 0) {
                let materialList = submitData.materials;
                for (let i = 0; i < materialList.length; i++) {
                    let item = materialList[i]
                    if (item.IS_NEED === "1" || item.isneed === "1") {
                        if (!item.files || item.files.length <= 0) {
                            return false
                        }
                    }
                }
			}
			return true;
		},
        /**
         * 获取材料列表
         */
        getMaterialList() {
            if (this.itemInfo && this.itemInfo.CLXX) {  // CLXX 材料信息，是存在store里面
                let resData = this.itemInfo.CLXX;
                let target = [];
                for (const item of resData) {
                    item.dgVisible = false;
                    item.clmc = item.MATERIAL_NAME; // 材料名称 
                    item.isneed = item.IS_NEED_TEXT === "是" ? 1 : 0; // 是否必须 
                    target.push(item);
                }
                this.materialList = target;
            }
        },
        /* 上传的文件信息 */
        uploadFileSuccess(e) {
            console.log("upload OK: ", e);
            this.materials = e;
        },
        /**
         * 历史表单数据共享获取
         */
        getHistoryFormDataList() {
            let param = {
                sxid: this.itemInfo.ITEM.TASK_CODE
            }
            let userInfo = this.$store.getters.getUserInfo
            if( userInfo ) {
                param.tyxydm = userInfo.shxydm
            }
            console.log("历史表单数据共享参数>>>", param)
            this.$httpApi.get("/dev-api/userBusiness/list", param).then((res) => {
                if (res.code === 200 && res.rows.length > 0) { 
                    console.log("历史表单数据共享>>>", res);
                    let resList = JSON.parse(JSON.stringify(res.rows))
                    // 处理数据
                    this.allFormDataList = resList
                    // this.tableData = resList
                    let allDataList = this.allFormDataList
                    let pageSize = this.pageSize
                    let pageNum = this.pageNum
                    let endIndex
                    if (pageNum * pageSize > allDataList.length) {
                        endIndex = allDataList.length
                    } else {
                        endIndex = pageNum * pageSize
                    }
                    this.total = allDataList.length
                    for (let i = (pageNum - 1) * pageSize; i < endIndex; i++) {
                        this.tableData.push(allDataList[i])
                    }
                    console.log("最后获取到的数据", this.tableData)
                }
            })
            .catch((e) => {
            });

        },
        /**
         * 表单数据共享点击事件
         */
        fillFormSubData(itemdata) {
            console.log("表单数据共享点击>>>", itemdata)
            this.getFormInfo(itemdata.id)
            // 填充表单
            setTimeout(() => {
                let data = this.formData;
                console.log("填充的表单数据:", data);
                this.fillFormData(data);
                //关闭对话框
                this.dgVisible = false
            }, 100);
        },
        /**
         * 点击 依据事件ID查询表单信息
         *
         * @param { String } id [ 传入的 ID ]
         * @return {  } [ 返回的数据，带表单 ]
         */
        getFormInfo(el) {
            let loadingInstance = Loading.service({
            target: this.$refs.declareContainer,
            });
            let url = "/dev-api/userBusiness/queryFormData"
            let params = {
            bsnum: el
            }
            this.$httpApi.get(url, params)
            .then((res) => {
                loadingInstance.close();
                console.log("11111111111111111111111111", res)
                if (res.code === 200) {
                    let target = res.data.subdata
                    let resData = JSON.parse(target)
                    console.log("转义后的resData", resData)
                    this.formData = resData
                }
            })
            .catch((err) => {
                loadingInstance.close();
            })
        },
        // 表单数据分页
        changePageNum(index) {
            this.pageNum = index
            this.tableData = []
            let allDataList = this.allFormDataList
            let pageSize = this.pageSize
            let pageNum = this.pageNum
            let endIndex
            if( pageNum * pageSize > allDataList.length ) {
                endIndex = allDataList.length
            }else {
                endIndex = pageNum * pageSize
            }
            this.total = allDataList.length
            for( let i = (pageNum - 1) * pageSize; i < endIndex; i++ ) {
                this.tableData.push(allDataList[i])
            }
        },
		/**
		 * 获取表单的 json 数据
		 */
		getFromData() { 
			if( this.itemInfo ) {
				this.itemInfo.formid = "72c92468306d45e8bc471fb1cb2a3b0b";			// 怎么是写死的？应该在哪里获取？
				if( !this.itemInfo.formid ) {
					console.log("----------------- 表单ID不能为空 -----------------");
					return;
				}
				this.$nextTick(() => {
					// 以服务的方式调用的 Loading 需要异步关闭
					this.$httpApi
					.get(`/dev-api/basicinfomanage/info/${this.itemInfo.formid}`, {})
					.then((res) => {
						console.log("res表单数据>>>:", res);
						if( res.code == 200 ) {
							this.formData = res.data;	// 原始数据
							if( this.formData.formContent ) {		// 表单内容存在
								this.formContent = JSON.parse(this.formData.formContent);
                                console.log("获取的form>>>", this.formContent)
								// console.log("处理后的表单数据>>>" + JSON.stringify(this.formContent)); 	// 需要JSON格式的
								setTimeout(() => {		// 填充数据
									this.fillFormData();
								}, 500);
							}
						}
					})
					.catch((err) => {
					});
				});
			}
		}, 
		/**
		 * 项目信息数据填充
		 */
		fillFormData(data) {
			if( data == null ) {
				// 申报账户（公司）基本信息: 假数据? 这数据应该是从后台请求到的
				data = this.companyBaseInfo;
			}
			// 项目信息填充
			if( this.$refs.parseFormViewDiv ) {
				this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
			}
		},
        /**
         * 处理表单数据，方便上传
         */
        handleSubmitData(isTempSave, projectFormData) {
            let submitData = {};
            // 从我的项目进入 
            if(this.projectItem) { 
                let bsdata = this.projectItem.bsdata;   
                let formInfo = [
                    {
                    bsnum: this.formData.bsnum || "", // 业务流水号
                    id: this.formData.id || "", // 业务表单ID 新申报时为空
                    formdata: JSON.stringify(this.formContent), // 这个可以不传
                    subdata: projectFormData ? JSON.stringify(projectFormData) : "",
                    formtype: this.formData.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
                    formid: this.formData.formid,
                    },
                ];
                //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
                let materials;
                if (this.materials && this.materials.length > 0) {
                    materials = this.materials;
                } else {
                    materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
                }
                submitData = {
                    baseinfo: bsdata.baseinfo, // 基本信息
                    formInfo: formInfo, // 表单信息
                    materials: materials, // 材料信息
                    operator: bsdata.operator,
                };
                console.log("从待办件进来后的要提交的数据:", submitData);
                return submitData;
            } else {
                let itemInfo;
                if (this.currentBusinessInfo && this.currentBusinessInfo.masterinfo) {
                    // 从在办件进入masterinfo才有值
                    itemInfo = this.currentBusinessInfo.masterinfo;
                } else {
                    itemInfo = this.itemInfo;  // 正常情况
                }

                let formInfo = [
                    {
                        bsnum: itemInfo.bsnum || "", // 业务流水号
                        id: this.formData.id || "", // 业务表单ID 新申报时为空
                        formdata: JSON.stringify(this.formContent), // 这个可以不传
                        subdata: projectFormData ? JSON.stringify(projectFormData) : "{}",
                        formtype: itemInfo.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
                        formid: itemInfo.formid,
                    },
                ];

                let infoItem = itemInfo.ITEM
                let baseInfo = {
                    areaid: "", //暂时传空
                    areaname: itemInfo.regionName || "",//***
                    sxid: infoItem.TASK_CODE || "", // 事项ID
                    bsnum: itemInfo.bsnum || "",//***
                    bstype: itemInfo.bstype || this.bstype || "0", // 业务类型1企业2个人
                    cnsj: itemInfo.cnsj || infoItem.PROMISE_DAY || "", // 承诺时间
                    cnsjdw: itemInfo.cnsjdw || "", //承诺时间单位  ***
                    deptid: itemInfo.deptCode || itemInfo.deptid || "",//暂时传空,后期调整 ***
                    deptname: itemInfo.deptName || itemInfo.deptname || infoItem.DEPT_NAME || "",
                    sxmc: itemInfo.name || itemInfo.sxmc || infoItem.TASK_NAME || "", // 事项名称
                    jljb: itemInfo.jljb || "", // 是否即办件 ***
                    flowid: itemInfo.flowId || itemInfo.flowId || "", // 办理流程ID ***
                    applicantid: itemInfo.applicantid || this.loginUserInfo.id || "", // 申请人ID
                    appname: itemInfo.appname || this.loginUserInfo.name || "", // 申请人姓名
                    appcompany: itemInfo.appcompany || this.enterpriseInfo.unitname || "", // 申请人公司

                    type: itemInfo.handerType || "1", // 业务来源 1PC 2窗口
                    status: itemInfo.status || "", // 业务办理状态 0在办1正常办结2异常办结3补交4特别程序5预受理 ***
                    ssgzr: itemInfo.ssgzr || "", // 所属工作日 ***
                };
                //todo 目前是走个人的,后续得加判断是否是企业bstype==1

                // 经办人信息
                let agentInfo = {
                    agentuserid: this.loginUserInfo.id,
                    agentname: this.tableAgentInfo[0].content || "",
                    agentcertificatetype: "100001",
                    agentcertificatename: "身份证",
                    agentcertificatenumber: this.tableAgentInfo[1].content || "***",
                    agentmobilephone: this.tableAgentInfo[2].content || "",
                    agentgender: "2", //性别
                    agentbirthday: this.loginUserInfo.birthday || "",
                    agentaddress: this.loginUserInfo.address || "",
                    agentemail: this.loginUserInfo.email || "",
                };

                // 申报人信息
                let reporter = {
                    operateid: this.loginUserInfo.id || "",
                    operateaccount: this.loginUserInfo.loginId || "",
                    optname: this.tableBaseInfo[0].content || "",
                    optcertificatetype: "100001",
                    optcertificatename: "身份证",
                    optcertificatenumber: this.tableBaseInfo[3].content,
                    optmobilephone: this.tableBaseInfo[2].content || "",
                    optgender: "1",
                    optbirthday: this.loginUserInfo.birthday || "",
                    optaddress: this.tableBaseInfo[5].content || "",
                    optemail: this.tableBaseInfo[4].content || "",
                };

                // 再次申报的时候 要用到currentBusinessInfo的值进行赋值
                if (this.currentBusinessInfo) {
                    reporter = this.currentBusinessInfo.masterinfoReporter;
                }

                //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
                let materials;
                if (this.materials && this.materials.length > 0) {
                    materials = this.materials;
                } else {
                    materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
                    // 循环赋值，将样表里面的参数多存一个yburl
                }
                //处理相关字段,避免申报出错
                for (let i = 0; i < materials.length; i++) {
                    let guid = materials[i].ROWGUID
                    let isneed = materials[i].isneed
                    let yburl = "";
                    if (materials[i].EXAMPLE_GUID[0].FILEPATH) {
                    yburl = materials[i].EXAMPLE_GUID[0].FILEPATH
                    } else {
                    yburl = ""
                    }

                    materials[i].clbm = guid
                    materials[i].clid = guid
                    materials[i].yburl = yburl
                    materials[i].isneed = isneed;

                }

                submitData = {
                    baseinfo: baseInfo, // 基本信息
                    formInfo: formInfo, // 表单信息
                    materials: materials, // 材料信息
                    operator: {
                    apptype: this.bstype, // 申报类型 1企业 2个人
                    enterprise: this.enterpriseInfo, // 企业信息
                    agent: agentInfo, // 经办人信息
                    reporter: reporter,
                    },
                };
                console.log("提交的参数>>>:", submitData);
                return submitData;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-container {
    position: relative;
}
.hisform-btn {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 99;
}
</style>