<template>
    <!-- 确认基本信息 -->
    <div class="baseinfo-container">
        <el-tabs type="border-card">
            <el-tab-pane label="申请人（法人）信息">
                <div v-if="tableBaseInfo.length>0">
                <div class="formItem">
                <span class="itemTitle">{{tableBaseInfo[0].title}} <span
                    class="required">*</span> </span>
                    <el-input class="matchParent" disabled v-model="tableBaseInfo[0].content">
                    </el-input>
                </div>
                <div class="formItem">
                <span class="itemTitle">{{tableBaseInfo[1].title}} <span
                    class="required">*</span> </span>
                    <div class="matchParent flex-row-between">
                    <el-select value="1" disabled>
                        <el-option label="统一社会信用代码" value="1"></el-option>
                    </el-select>
                    <el-input class="matchParent" style="margin-left: 10px" disabled
                                v-model="tableBaseInfo[1].content">
                    </el-input>
                    </div>
                </div>
                <div class="formItem">
                <span class="itemTitle">{{tableBaseInfo[2].title}} <span
                    style="color: red">*</span> </span>
                    <el-input class="matchParent" v-model="tableBaseInfo[2].content">
                    </el-input>
                </div>
                <div class="formItem">
                <span class="itemTitle">{{tableBaseInfo[3].title}} <span
                    style="color: red">*</span> </span>
                    <el-input class="matchParent" v-model="tableBaseInfo[3].content">
                    </el-input>
                </div>
                <div class="formItem">
                <span class="itemTitle">{{tableBaseInfo[4].title}} <span
                    style="color: red">*</span> </span>
                    <div class="matchParent flex-row-between">
                    <el-select value="1">
                        <el-option label="身份证" value="1"></el-option>
                    </el-select>
                    <el-input class="matchParent" style="margin-left: 10px"
                                v-model="tableBaseInfo[4].content">
                    </el-input>
                    </div>
                </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="经办人基本信息">
            <div v-if="tableAgentInfo.length>0">
                <div class="formItem">
                <span class="itemTitle">{{tableAgentInfo[0].title}} <span
                    class="required">*</span> </span>
                    <el-input class="matchParent" disabled v-model="tableAgentInfo[0].content">
                    </el-input>
                </div>
                <div class="formItem">
                <span class="itemTitle">{{tableAgentInfo[1].title}} <span
                    style="color: red">*</span> </span>
                    <div class="matchParent flex-row-between">
                    <el-select value="1" disabled>
                        <el-option label="身份证" value="1"></el-option>
                    </el-select>
                    <el-input class="matchParent" style="margin-left: 10px" disabled
                        v-model="tableAgentInfo[1].content">
                    </el-input>
                    </div>
                </div>
                <div class="formItem">
                <span class="itemTitle">{{tableAgentInfo[2].title}} <span
                    class="required">*</span> </span>
                    <el-input class="matchParent" v-model="tableAgentInfo[2].content">
                    </el-input>
                </div>
                <div class="formItem">
                    <span class="itemTitle">{{tableAgentInfo[3].title}} </span>
                    <el-input class="matchParent" v-model="tableAgentInfo[3].content">
                    </el-input>
                </div>
                <div class="formItem">
                    <span class="itemTitle">{{tableAgentInfo[4].title}} </span>
                    <el-input class="matchParent" v-model="tableBaseInfo[4].content">
                    </el-input>
                </div>
            </div>
            </el-tab-pane> 
        </el-tabs>
    </div>
</template>

<script> 

export default {
    components: { 
    },
    props: { 
        tableBaseInfo: {},      // 基本信息
        tableAgentInfo: {},     // 经办人基本信息 
    },
    data() {
        return {   
        }
    },
    created() { 
    },
    mounted() { 
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
</style>