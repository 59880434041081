<template>
    <!-- 申请告知 0 -->
    <div class=" notification-container">
        <div class="mb-20 notification-content">
            <h2 style="text-align: center; padding: 10px 0 ">{{ propsData.title }}</h2>
            <div>{{ propsData.content }}</div>
            <p>申请人严格按要求提供相关申请材料，并按指引上传。</p>
            <p>申请人郑重承诺：上传的申请材料、证件扫描件与书面申请材料、证件原件一致， 均客观真实、合法有效，不存在隐瞒、伪造、篡改等任何违法情形。</p>
            <p> 申请人愿意按审批流程要求现场提交书面申请材料供校验核对， 如上传材料与现场提交书面材料不一致或存在隐瞒、伪造、篡改等违法情形， 愿意承担一切法律后果，包括但不限于终止审批流程、撤销审批结果以及相应的行政处罚或刑事责任。</p>
        </div>
        <div class="flex-row notification-btn">
            <el-button 
            size='mini'
            circle
            :type="btnStatus ? 'success' : 'danger' " 
            :icon=" btnStatus ? 'el-icon-check' : 'el-icon-close' "
            @click="btnConfirm"
            >
            </el-button>
            <p style="padding-left: 10px;"><span class="confirm-prompt">(点击确认)</span>我已知晓申报要求，并对此负责</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            propsData: {
                title: '《申请告知》',
                content: `   申请人对所提请行政审批事项的法律、法规和政策依据， 准予行政审批的条件、标准和技术要求， 需要提交材料的名称、方式和期限等内容，已全部知悉。 `,
            },
            // 按钮状态
            btnStatus: false,
        }
    },
    methods: {
        // 按钮确认点击
        btnConfirm() {
            this.btnStatus = !this.btnStatus
        }
    }
}
</script>

<style lang="scss" scoped>
.notification-container {
    width: 100%;
    height: 100%; 
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 20px;
}
.notification-btn {
    font-size: 14px;
    font-weight: 700; 
}
// 确认提示
.confirm-prompt {
    color: red;
}
</style>