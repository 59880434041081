<template>
	<div class="declare">
		<!-- 横幅 -->
		<div class="banner-size">
			<div class="flex-row banner-limit banner-info">
				<span class="banner-fMax">申报详情</span>
				<span class="banner-fMin banner-other">送政策、送服务，助推企业发展</span>
			</div>
		</div>
		<!-- 内容展示 -->
    	<div class="width-limit declare-container" ref="declareContainer">
			<!-- 具体内容容器 -->
			<div class="declare-content">
				<!-- 标题 -->
				<h1 class="declare-title">
					{{ sxmc || "事项名称" }}
				</h1>   
				<!-- steps步骤条 -->
				<el-steps class="mb-20" :active="stepNum" finish-status="success">
					<el-step 
					v-for="item in stepsList" :key="item.index"
					align-center
					:title="item.title"
					></el-step>
				</el-steps>

				<!-- show step -->
				<div class="mb-20"> 
					<!-- 申请告知 -->
					<ApplicationNotification 
					v-show="stepNum === 0"
					class="step-show"
					ref="notificationIsconfirm"
					></ApplicationNotification>
					<!-- 确认基本信息 --> 
					<BaseInfo
					v-show="stepNum === 1"
					class="step-show"
					ref="refBaseInfo"
					:tableBaseInfo="tableBaseInfo"
					:tableAgentInfo="tableAgentInfo" 
					></BaseInfo> 
					<!-- 2 -->
					<FormData 
					ref="refFormData"
					v-show="stepNum === 2"
					:enterpriseInfo="enterpriseInfo"
					></FormData>
					<!-- 3 -->
					<div v-if="stepNum === 3">
						<div class="mask-container">
							<div class="mask-content"></div>
							<div>基本信息</div>
							<BaseInfo 
							class="step-show"
							ref="refBaseInfo"
							:tableBaseInfo="tableBaseInfo"
							:tableAgentInfo="tableAgentInfo" 
							></BaseInfo> 
							<div>表单信息，材料信息</div>
							<FormData  
							></FormData>
						</div>
					</div>
					<!-- 4 -->
					<div v-show="stepNum === 4">4</div>					
				</div>

				<!-- 按钮 -->
				<div class="flex-row btn-items">
					<el-button class="btn-show " type="warning" v-show="stepNum !== 0" @click="lastStep">上一步</el-button>
					<el-button class="btn-show " type="info" plain @click="submitAction(true)">暂存</el-button>
					<el-button class="btn-show " type="primary" @click="nextStep">下一步</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApplicationNotification from './components/applicationNotification';		// 申请告知
import BaseInfo from './components/baseInfo/index';			// 确认基本信息
import FormData from './components/fromData/index';			// 填写表单，上传材料
import {mapGetters} from "vuex";
// import {Loading} from "element-ui";						// 加载动画

export default {
	components: {
		ApplicationNotification,	// 申请告知
		BaseInfo,					// 基本信息
		FormData,					// 表单 && 材料
	},
	data() {
		return {
			/* 事项名称 */
			sxmc: "",   
			/* steps列表 */
			stepNum: 0,
			stepsList: [
				{
					title: '申请告知',
					index: 0,
				},
				{
					title: '确认基本信息',
					index: 1,
				},
				{
					title: '填写表单，上传材料',
					index: 2,
				},
				{
					title: '确认',
					index: 3,
				},
				{
					title: '提交成功',
					index: 4,
				}
			],
			identityType: 1, // 申报类型 1企业 2个人 根据用户信息是否有社会信用代码判断
			/* 基本信息 */
			enterpriseInfo: {}, 	// 更新数据格式后的企业基本信息
			tableBaseInfo: [], 		// 基本信息  
			tableAgentInfo: [],		// 经办人信息
		}
	},
    computed: {
		...mapGetters({
			loginUserInfo: "getUserInfo", 			// 账号登录后基本信息
			childItem: "getCurrentDeclareItem",		// 申报事项的基本信息
			gsBaseInfo: "getAccountBaseInfo",		// 申报账户（公司）基本信息
			projectItem: "getCurrentProjectItem", 	// 从我的项目进入时有值
		}),
    },
	mounted() {
		// 进来的初始信息 
		console.log("账号登录后基本信息>>>", this.loginUserInfo); 
		console.log("申报事项的基本信息>>>", this.childItem);
		console.log("申报账户（公司）基本信息>>>", this.gsBaseInfo); 
		console.log("从我的项目进入时有值>>>", this.projectItem);
		// 判断 企业 or 个人账号 , * 应该在登录的时候就判断并存值
		this.isAccountTyoe();
		// 获取事项名称
		this.getTitle();
		// 获取表单的 json 数据
		// this.getFromData();
		// 更新基本信息
		this.upBaseInfo()
	},
	methods: {
		/**
		 * 判断账号类型 企业 用户
		 */
		isAccountTyoe() {
			if( this.loginUserInfo.shxydm ) {
				this.identityType = 1;
			} else {
				this.identityType = 2; 
			}
			console.log("账号类型是>>>", this.identityType)
		},
		/**
		 * 获取事项名称
		 */
		getTitle() {
			this.sxmc = this.childItem.name || this.childItem.ITEM.TASK_NAME || ""; 	// 正常情况
		},
		/**
		 * 初始化基本信息
		 */
		upBaseInfo() { 
			// 更新基本信息
			if( this.identityType === 1 ) {
				this.upEnterpriseInfo();			// 更新企业信息
				this.upEnterpriseBaseInfo()		// 更新企业信息
			} else {
				this.upPersonalBaseInfo()		// 更新个人信息
			}

			if( this.loginUserInfo ) {			// 更新经办人信息
				this.upAgentBaseInfo()
			}
		},
		/**
		 * 更新企业信息
		 */
		upEnterpriseInfo() {
			if( this.gsBaseInfo ) {			// 如果有公司信息，就更新格式
				this.enterpriseInfo = {
					unitname: this.gsBaseInfo.name || "",
					unitcardtype: "100008", // 机构证件类型  100008是工商营业执照
					unitcardcode: this.gsBaseInfo.shxydm || "", // 统一信用代码
					legalperson: this.gsBaseInfo.frdb || "", //法人名字
					legalcardtype: "100001", // 法人证件类型 100001是身份证
					legalcardnum: this.gsBaseInfo.frsfz || "****", //法人身份证
					unitaddress: this.gsBaseInfo.zcdz || "",
					unittel: this.gsBaseInfo.qycz || "",
					unitpostcode: "000000",
					unitemail: this.gsBaseInfo.qyyx || "",
				}
			}
		},
		/**
		 * 用于显示企业的基本信息
		 */
		upEnterpriseBaseInfo() { 
			this.tableBaseInfo = [
				{title: "姓名", content: this.enterpriseInfo.unitname},
				{title: "证件号码", content: this.enterpriseInfo.unitcardcode},
				{title: "证件地址", content: this.enterpriseInfo.unitaddress},
				{title: "代表人姓名", content: this.enterpriseInfo.legalperson},
				{title: "代表人证件", content: this.enterpriseInfo.legalcardnum},
				{title: "机构电话", content: this.enterpriseInfo.unittel},
				{title: "申报对象", content: "单位"},
			];
		},
		/**
		 * 个人的情况，更新个人基本信息
		 */
		upPersonalBaseInfo() {
			this.tableBaseInfo = [
				{title: "姓名", content: this.loginUserInfo.name || ""},
				{title: "对象", content: "个人"},
				{title: "电话", content: this.loginUserInfo.phone || ""},
				{
					title: "证件号码",
					content: this.loginUserInfo.idcard || "",
				},
				{title: "邮箱", content: this.loginUserInfo.email || ""},
				{
					title: "地址",
					content: this.loginUserInfo.address || "",
				},
			];
		},
		/**
		 * 更新经办人信息，登录人就是经办人  用于显示经办人的信息
		 */
		upAgentBaseInfo() {
			this.tableAgentInfo = [
				{ 
					title: "姓名", 
					content: this.loginUserInfo.name || ""
				},
				{
					title: "证件号码",
					content: this.loginUserInfo.idcard || "***",
				},
				{ 
					title: "手机号码", 
					content: this.loginUserInfo.phone || ""
				},
				{
					title: "固定电话",
					content: this.loginUserInfo.address || "",
				},
				{
					title: "证件地址",
					content: this.loginUserInfo.address || "",
				},
				// { title: "经办人邮箱", content: this.loginUserInfo.email || "" },

			];
		},
		/**
		 * 上一个步骤
		 */
		lastStep() {
			this.stepNum -= 1;
		},
		/**
		 *  下一个步骤
		 */
		nextStep() {
			switch( this.stepNum ) {
				case 0:
					this.refNotification()
					break;
				case 1:
					this.confirmBaseInfo()
					break;
				case 2:
					this.check();
					break;
				case 3: 
					break;
			}
		},
		/**
		 * 暂存
		 */
		temporaryStorage() {
			console.log("暂存")
		},
		/**
		 * 检查
		 */
		check() {
			//检查附件必传
			let isMeet = this.$refs.refFormData.checkMaterial(); 
			console.log("isMeet>>>", isMeet)
			if( isMeet ) {
				this.$confirm(`确认申请事项${this.sxmc}?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// this.submitAction()
					this.stepNum += 1;
				}).catch(() => {
					console.log("错误")
				});
			}else {
				this.$alert('必传材料必须上传才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
					confirmButtonText: '确定',
					callback: action => {
					}
				});
			}
		},
		/**
		 * ref=notificationIsconfirm 申请告知 1
		 */
		refNotification() {
			let res = this.$refs.notificationIsconfirm;
			console.log("申请告知>>>", res)
			if( !res.btnStatus ) {
				this.$confirm('请确认已经阅读完提示，并点击确认按钮?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(() => {
				this.$message({
					type: 'success',
					message: '请点击确认按钮'
				});
				}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});          
				});
			} else {
				this.stepNum += 1;
			}
		},
		/**
		 * 表单，材料 确认
		 */
		confirmBaseInfo() {
			// let res = this.$refs.notificationIsconfirm;
			// console.log("申请告知>>>", res)
			if( false ) {
				this.$confirm('请确认已经阅读完提示，并点击确认按钮?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(() => {
				this.$message({
					type: 'success',
					message: '请点击确认按钮'
				});
				}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});          
				});
			} else {
				this.stepNum += 1;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./index.scss"

</style>